<template>
  <CCard
    :class="{ '_minimized': !showAll && isMobile }"
    class="card shadow-none overflow-hidden"
  >
    <CCardHeader class="card-header bg-dark d-flex align-items-center">
      <span>Overall Volume</span>

      <slot name="header"/>

      <label class="ml-auto mb-0 col-form-label-sm" @click="isRsi = changeEnableNavigation">{{ $t('delta.nav') }}</label>
      <CSwitch
        v-if="isDesktop"
        color="primary"
        shape="pill"
        @update:checked="changeEnableNavigation"
        :checked="enableNavigation"
        class="mx-2 switch-custom"
      />


      <span
        v-if="isDesktop"
        class="nav-description rounded-circle bg-second-card-bg popover-wrap d-inline-flex justify-content-center flex-shrink-0"
        v-c-tooltip="{
          header: $t('delta.nav'),
          content: $t('delta.navDescription'),
          appendToBody: true,
          popperOptions: {
            strategy: 'fixed'
          }
        }"
      >
        ?
      </span>
    </CCardHeader>
    <CCardBody class="card-body overflow-hidden pb-0">
      <div class="table-wrap">
        <table class="table">
          <thead>
          <tr>
            <th
              v-for="(field, i) in fields"
              :key="i"
            >
              {{ field.label }}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="(item, i) in reverseBars"
            :key="i"
            :class="item._classes"
            @click="rowClick(item)"
          >
            <td :class="item._cellClasses.timeStr">{{ item.timeStr }}</td>
            <td :class="item._cellClasses.delta">{{ item.delta }}</td>
            <td :class="item._cellClasses.volume">{{ item.volume }}</td>
            <td :class="item._cellClasses.open">{{ Math.floor(item.open * 100) / 100 }}</td>
            <td :class="item._cellClasses.close">{{  Math.floor(item.close * 100) / 100 }}</td>
          </tr>
          </tbody>
        </table>
        <transition name="table-show-all">
          <div v-if="isMobile && !showAll" class="table-show-all">
            <button @click="$emit('update:showAll', true)">
              {{ $t('delta.expand') }}

              <CIcon
                name="arrow-trends"
                class="arrow-bottom ml-1"
                width="0.6rem"
              />
            </button>
          </div>
        </transition>
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "OverallVolume",
  model: {
    prop: 'enableNavigation',
    event: 'change-enable-navigation'
  },
  props: {
    enableNavigation: {
      type: Boolean
    },
    filteredBars: {
      type: Array,
      default: () => []
    },
    selectedBar: {
      type: Object,
      default: () => null
    },
    showAll: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fields: [
        {key: 'timeStr', label: 'TIME'},
        {key: 'delta', label: 'DELTA'},
        {key: 'volume', label: 'VOLUME'},
        {key: 'open', label: 'OPEN'},
        {key: 'close', label: 'CLOSE'},
      ]
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'mediaQuery/isMobile',
      isTablet: 'mediaQuery/isTablet',
      isDesktop: 'mediaQuery/isDesktop',
    }),
    reverseBars() {
      if (!this.filteredBars || !Array.isArray(this.filteredBars)) return []

      const arr = [].concat(this.filteredBars)
      arr.reverse()
      return arr
    }
  },
  methods: {
    rowClick(row) {
      this.$emit('change-bar', row)
    },
    viewRowEvent() {
      setTimeout(() => {
        const className = this.selectedBar._classes
        const el = document.querySelector('.' + className)
        const parent = document.getElementById('volume-group')
        if (!el || !parent) return

        if (this.isElementInViewport(el, parent)) return
        el.scrollIntoView({behavior: 'smooth', block: 'center'})
      })
    },
    isElementInViewport(el, parent) {
      const elTop = el.getBoundingClientRect().top
      const elBottom = el.getBoundingClientRect().bottom
      const parentTop = parent.getBoundingClientRect().top
      const parentBottom = parent.getBoundingClientRect().bottom

      return (
        elTop >= parentTop &&
        elBottom <= parentBottom
      )
    },
    changeEnableNavigation() {
      const value = !this.enableNavigation
      this.$emit('change-enable-navigation', value)
      this.$store.dispatch('setKey', {
        namespace: 'overallVolume',
        key: 'enableNavigation',
        value: value
      })
    }
  },
  watch: {
    'selectedBar': { // scroll to selected row
      handler: function (newItem, oldItem) {
        if (!this.isDesktop) return
        if (!newItem) return
        if (oldItem && oldItem.timeTv === newItem.timeTv) return

        if (!this.enableNavigation) return
        this.viewRowEvent()
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  margin-bottom: 0;
  background: var(--second-card-bg);

  @include media-breakpoint-down(sm) {
    transition: height .5s;

    &._minimized {
      height: 200px !important;
    }
  }

  .card-header {

    @include media-breakpoint-down(lg) {
      padding-top: .315rem;
      padding-bottom: .315rem;
      white-space: nowrap;
    }

    @include media-breakpoint-down(md) {
      font-size: 0.75rem;
      padding-left: 1rem;
      padding-right: .75rem;
    }

    @include media-breakpoint-down(sm) {
      padding: 0.375rem .875rem;
    }
  }

  .card-body {
    position: relative;

    @include media-breakpoint-down(md) {
      padding: 0 1rem;
    }

    @include media-breakpoint-down(sm) {
      padding-left: .875rem;
      padding-right: .875rem;
    }
  }
}

.table-wrap {
  height: 100%;
  overflow: auto;
}

.table {
  thead tr th {
    background: var(--second-card-bg);
    position: sticky;
    top: -1px;
    z-index: 1;
  }

  th,
  td {
    vertical-align: middle;

    @include media-breakpoint-down(md) {
      padding: .5625rem .25rem;
    }
  }

  th {
    @include media-breakpoint-down(lg) {
      font-size: .75rem;
    }

    @include media-breakpoint-down(md) {
      font-size: .5625rem;
    }
  }

  td {
    @include media-breakpoint-down(lg) {
      font-size: .75rem;
    }

    @include media-breakpoint-down(md) {
      font-size: .6875rem;
      line-height: .8125rem;
    }
  }

  .active-row-success {
    background: var(--market-volume-success) !important;
  }

  .active-row-danger {
    background: var(--market-volume-danger) !important;
  }
}

.popover-wrap {
  cursor: pointer;
  display: inline-flex;
  width: 1rem;
  height: 1rem;
  font-size: 0.75rem;
}

.btn-nav {
  &.btn-outline-white {
    &:not(:hover) {
      background: var(--dark);
    }
  }

  @include media-breakpoint-down(md) {
    font-size: .75rem;
    line-height: 1rem;
  }
}

.table-show-all {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, rgba(235, 237, 239, 0) 0%, rgba(235, 237, 239, 0.5) 50%, rgba(235, 237, 239, 1) 100%);

  button {
    background: var(--dark);
    border-radius: 2.125rem;
    border: none;
    color: var(--white);
    font-size: .75rem;
    line-height: .9375rem;
    height: 1.6875rem;
    padding: 0 .75rem;

    .arrow-bottom {
      transform: rotate(180deg) translateY(-1px);
    }
  }

  &-enter-active, &-leave-active {
    will-change: opacity;
    transition: opacity .5s;
  }
  &-enter, &-leave-to {
    opacity: 0;
  }
}

body {
  .c-dark-theme {
    .table-show-all {
      background: linear-gradient(180deg, rgba(24, 25, 36, 0) 0%, rgba(24, 25, 36, 0.5) 50%, rgba(24, 25, 36, 1) 100%);
    }
  }
}

.nav-description {
  margin-left: auto;

  @include media-breakpoint-up(xl) {
    margin-left: 0rem;
  }
}
</style>
