var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CModal",
    _vm._g(
      {
        attrs: {
          title: _vm.modalTitle,
          show: _vm.show,
          centered: "",
          closeOnBackdrop: "",
          scrollable: "",
        },
        on: { "update:show": _vm.closeModal },
        scopedSlots: _vm._u([
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "w-100 d-flex justify-content-end" },
                  [
                    _c(
                      "CLoadingButtonCustom",
                      {
                        staticClass: "mr-2",
                        attrs: {
                          color: "primary",
                          loading: _vm.loading,
                          disabled: _vm.disabled,
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.save.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("general.save")) + " ")]
                    ),
                    _c(
                      "CButton",
                      {
                        attrs: { color: "dark" },
                        on: { click: _vm.closeModal },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("general.cancel")) + " ")]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    ),
    [
      _c(
        "v-date-picker",
        _vm._g(
          _vm._b(
            {
              attrs: {
                value: _vm.value,
                popover: {
                  visibility: "visible",
                },
                "is-dark": _vm.$store.getters.darkMode,
                locale: _vm.$root.$i18n.locale,
                "is-expanded": "",
              },
              on: {
                input: function ($event) {
                  _vm.inputValue = $event
                },
              },
            },
            "v-date-picker",
            _vm.calendarProps,
            false
          ),
          _vm.$listeners
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }