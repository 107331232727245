<template>
  <CCard class="card h-100 mb-0">
    <CCardBody
      class="card-body"
    >
      <custom-scroll>
        <div
          v-move-to-top="vMoveToTopOptions"
          id="volume-group"
          ref="cardBody"
          class="card-body-content no-gutters"
        >
          <div
            :style="tvStyle"
            class="tv_chart_wrap"
            data-onboarding-step="3/13/14"
          >
            <div
              id="tv_chart_container"
              class="h-100 w-100"
              :class="{
              'border-second-card-bg': $store.getters.darkMode,
              'border-dark': !$store.getters.darkMode,
              'transparent-overlay': resizerMoving
            }"
            />

            <div v-if="isDesktop" class="resizer" @mousedown="resizerStart">
              <div class="resizer__inner" :class="resizerMoving ? '_move' : ''"/>
            </div>
          </div>

          <div
            ref="volumeGroup"
            :style="volumeGroupStyle"
            :class="{ '_is-loaded': firstDataLoaded }"
            class="market-volume"
            data-onboarding-step="4"
          >
            <select-date
              v-if="firstDataLoaded && isDesktop"
              v-model="filterDate"
              :first-bar="barsFirstItem"
              :last-bar="barsLastItem"
              data-onboarding-step="10"
            />

            <market-volume
              ref="marketVolume"
              v-if="firstDataLoaded"
              :ext-data="marketData"
              :filter-date="filterDate"
              :interval="interval"
              :filtered-bars="filteredBars"
              :exchange="exchange"
              @change-exchange="exchange = isGuest ? exchange : $event"
              :symbol="symbol"
              @change-symbol="symbol = isGuest ? symbol : $event"
              @data-loaded="setOverallVolumeStyle"
            >
              <template v-if="isMobile" #header>
                <select-date
                  v-model="filterDate"
                  :first-bar="barsFirstItem"
                  :last-bar="barsLastItem"
                  is-mobile
                  class="select-date-mobile"
                />
              </template>
            </market-volume>

            <div v-else class="volume-group__spinner">
              <CSpinner
                class="spinner"
                color="primary"
              />
            </div>
          </div>

          <div
            v-if="firstDataLoaded"
            :style="volumeGroupStyle"
            :class="{ '_minimized': !showAllOverallVolume && isMobile }"
            class="overall-volume-wrap"
          >

            <overall-volume
              :style="overallVolumeStyle"
              :filtered-bars="filteredBars"
              :selected-bar="selectedBar"
              :show-all.sync="showAllOverallVolume"
              @change-bar="setSelectedBar"
              :enable-navigation="enableNavigation"
              @change-enable-navigation="enableNavigation = $event"
              data-onboarding-step="11"
            >
              <template v-if="!isMobile && !isDesktop" #header>
                <select-date
                  v-model="filterDate"
                  :first-bar="barsFirstItem"
                  :last-bar="barsLastItem"
                  is-mobile
                  class="select-date-mobile"
                />
              </template>
            </overall-volume>
          </div>
        </div>
      </custom-scroll>
    </CCardBody>
    <notification-modal
      v-if="!isGuest"
      :key="showNotificationKey"
      :pairsData.sync="pairsData"
      :notification="notification"
      :showModal="showNotificationModal"
      :isFuturesForRerender.sync="isFuturesForRerender"
      :focusComment="true"
      @close="closeEvent"
    />
    <confirmModal
      v-if="!isGuest"
      :show="showConfirmModal"
      :title="$t('lk.notifications.deleteNotification')"
      @confirm="deleteDraw()"
      @close="showConfirmModal = false"
    >
      {{ $t('lk.notifications.deleteNotificationText') }}
    </confirmModal>
    <confirmModal
      v-if="!isGuest"
      :show="propsDropdown?.showConfirmRemoveSignalsPlotModal"
      :title="$t('tvPropsDropdown.confirmRemoveSignalsPlot') + '?'"
      @confirm="removeSignalsPlotCallback()"
      @close="propsDropdown.showConfirmRemoveSignalsPlotModal = false"
    >
      {{ $t('tvPropsDropdown.confirmRemoveSignalsPlotDesc') }}
    </confirmModal>
  </CCard>
</template>

<script>
import MarketVolume from "@/components/delta/group/MarketVolume"
import SelectDate from "@/components/delta/group/SelectDate"
import OverallVolume from "@/components/delta/group/OverallVolume"

import {widget} from '@/assets/js/charting_library'
import axios from 'axios'
import initDataAutosave from "@/assets/js/trandingViewInitAutosave"
import initDataGuestAutosave from "@/assets/js/trandingViewInitGuestAutosave"
import throttle from "lodash/throttle"
import studyDeltaWrap from "@/assets/js/delta/studyDeltaWrap"
import studyAccumDeltaWrap from "@/assets/js/delta/studyAccumDeltaWrap"
import studyFullAccumDeltaWrap from "@/assets/js/delta/studyFullAccumDeltaWrap"
import studyMA50_100_200 from "@/assets/js/delta/studyMA50_100_200"
import studyNatrWrap from "@/assets/js/delta/studyNatrWrap"
import studyVolatilityWrap from '@/assets/js/delta/studyVolatilityWrap'
import studyTrends from '@/assets/js/delta/studyTrends'
import studyRaketa from '@/assets/js/delta/studyRaketa'
import i18n from "@/i18n"
import {
  getAllChartsAutosave,
  getChartContentAutosave,
  removeChartAutosave,
  saveChartAutosave,
  getAllStudyTemplates,
  saveStudyTemplate,
  getStudyTemplateContent,
  removeStudyTemplate,
  getDrawingTemplates,
  saveDrawingTemplate,
  loadDrawingTemplate,
  removeDrawingTemplate
} from "@/assets/js/delta/autosaveMethods"
import OverallVolumeClass from "@/assets/js/OverallVolume.class"
import {symbolsConstants} from "@/assets/js/marketVolumeClasses/MarketVolumeItem.class"
import {mapGetters} from 'vuex'
import CustomScroll from "@/components/common/CustomScroll"
import NotificationModal from "@/components/notifications/NotificationModal"
import Notification from "@/assets/js/notifications/Notification.class"
import Condition from "@/assets/js/notifications/Condition.class"
import confirmModal from "@/components/common/confirmModal"
import {forIn} from "lodash"
import PropsDropdown from '@/assets/js/delta/PropsDropdown.class'
import {BUILD_PUBLIC_PATH} from '@/assets/js/constants'
import {CLIENT_ID_DEFAULT} from "@/shared/constants";
import { cloneDeep } from "lodash"

const tvStorageUrl = axios.defaults.baseURL + '/api/v1/tv_save_load/charts'
const clientId = CLIENT_ID_DEFAULT
const initialNotify = new Notification({conditions: [new Condition()]})

export default {
  name: "Delta",
  components: {
    MarketVolume,
    SelectDate,
    OverallVolume,
    CustomScroll,
    NotificationModal,
    confirmModal,
  },
  data() {
    return {
      isFuturesForRerender: false,
      tvWidget: null,
      time: null,
      rayId: null,
      initData: null,
      initChartTheme: null,
      headerBtnList: [],
      studyVolumeId: null,
      studyDeltaId: null,
      filterDate: null,
      bars: [],
      exchange: null,
      interval: null,
      symbol: null,
      selectedBar: null,
      enableNavigation: true,
      firstDataLoaded: false,
      firstRayDrawed: false,
      resizerMoving: false,
      resizerPos: null,
      volumeGroupStyle: {},
      overallVolumeStyle: {},
      tvStyle: {},
      showAllOverallVolume: false,
      notification: initialNotify.copy(),
      showNotificationModal: false,
      showNotificationKey: 0,
      pairsData: null,
      signalTimerId: null,
      varsNotification: {},
      activeSignal: [],
      activeSignals: {},
      drawnLines: {},
      signalLoading: false,
      showConfirmModal: false,
      dataConfirmModal: null,
      marketData: [],
      toSetExchange: null,
      toSetInterval: null,
      signalsLogList: {},
      propsDropdown: null,
      hideNotifications: false,
      hideSignalsPlot: false,
    }
  },
  //@todo пришлось поставить async, т.к. предварительно нужно получить сохраненные данные с сервера, если они есть,
  // нативные настройки load_last_chart и т.п. работают плохо - не восстанавливают интервал и символ
  // (ну символ могут еще восстановить, если его не указывать в опциях, но тогда, надо еще думать, как умолчания сделать)
  async created() {
    await this.getInitData()
    const trendsDataStore = {};

    const widgetOptions = {
      datafeed: new window.Datafeeds.UDFCompatibleDatafeed(axios.defaults.baseURL + '/api/v1/tv'),
      symbol: this.initData.symbol || 'OVERALL:BTCUSD',
      interval: this.initData.interval || this.initData.resolution || '60',
      container: 'tv_chart_container',
      library_path: BUILD_PUBLIC_PATH + '/charting_library/',
      locale: this.$root.$i18n.locale,
      saved_data: this.initData,
      saved_data_meta_info: {},
      save_load_adapter: {
        charts: [],
        studyTemplates: [],
        drawingTemplates: [],
        getAllCharts: () => getAllChartsAutosave({client_id: clientId}),
        removeChart: removeChartAutosave,
        saveChart: v => saveChartAutosave(v, {client_id: clientId}),
        getChartContent: v => getChartContentAutosave(v, {client_id: clientId}),

        getAllStudyTemplates: getAllStudyTemplates,
        saveStudyTemplate: saveStudyTemplate,
        getStudyTemplateContent: getStudyTemplateContent,
        removeStudyTemplate: removeStudyTemplate,

        getDrawingTemplates: getDrawingTemplates,
        saveDrawingTemplate: saveDrawingTemplate,
        loadDrawingTemplate: loadDrawingTemplate,
        removeDrawingTemplate: removeDrawingTemplate,
      },
      disabled_features: [
        'timezone_menu',
        'header_widget_dom_node',
        'symbol_search_hot_key',
        'header_symbol_search',
        'header_compare',
        'header_undo_redo',
      ],
      enabled_features: this.enabledFeatures,
      client_id: clientId,
      user_id: this.$store.state.user.userData.id,
      auto_save_delay: 5,
      fullscreen: false,
      autosize: true,
      theme: this.$store.getters.themeCapitalize,
      custom_css_url: BUILD_PUBLIC_PATH + '/charting_library_style.css',
      custom_indicators_getter: (PineJS) => {
        return Promise.resolve([ // for array indicators
          studyDeltaWrap(PineJS),
          studyAccumDeltaWrap(PineJS, this.tvWidget),
          studyFullAccumDeltaWrap(PineJS),
          studyMA50_100_200(PineJS),
          studyNatrWrap(PineJS),
          studyVolatilityWrap(PineJS, this.tvWidget),
          ...(this.isStudyTrendsEnabled ? [studyTrends(PineJS, trendsDataStore), studyRaketa(PineJS, [])] : [])
        ])
      }
    }

    let enable_features = localStorage.getItem('tv_enable_features');
    if (enable_features) {
      enable_features = enable_features.split(/\W+/);
      widgetOptions.disabled_features = widgetOptions.disabled_features.filter(v => !enable_features.includes(v));
      widgetOptions.debug = enable_features.includes('debug')
      if (widgetOptions.debug) window.tvWidget = this.tvWidget
    }

    if(this.isGuest){
      ['header_saveload', 'study_templates', 'chart_crosshair_menu', 'header_indicators'].forEach(item => {
        if(!widgetOptions.disabled_features.includes(item)){
          widgetOptions.disabled_features.push(item)
        }
      })
    }

    //@todo чтобы на кнопке появилось название сохранения и было связано с его id
    if (this.initData.id) {
      widgetOptions.saved_data_meta_info = {
        uid: this.initData.id,
        name: this.initData.name,
        description: this.initData.description
      }
    }

    window.Datafeeds.marketRequest = {
      'tf': widgetOptions.interval
    }
    if(this.isStudyTrendsEnabled){
      window.Datafeeds.trendsRequest = {
        enable: 1
      }
    }

    window.addEventListener('marketData', e => {
      this.marketData = e.detail
    })
    window.addEventListener('signalsData', e => {
      this.signalsUpdate(e.detail || [])
    })
    window.addEventListener('signalsLogData', e => {
      this.signalsLogUpdate(e.detail || {})
    })

    window.addEventListener('trendsData', e => {
      const key = e.detail.symbol + '_' + e.detail.resolution
      if(!trendsDataStore[key]){
        trendsDataStore[key] = [[], [], [], []]
      }
      e.detail.data.forEach((ots, k) => {
        ots.forEach(ot => {
          if(!trendsDataStore[key][k].includes(ot)){
            trendsDataStore[key][k].push(ot)
          }
        })
      })
    })

    /**
     * @type IChartingLibraryWidget
     */
    this.tvWidget = new widget(widgetOptions)
    this.tvWidget.onChartReady(this.onChartReady)
    this.enableNavigation = this.$store.state.settings?.overallVolume?.enableNavigation
    const resizerPosLocalStorage = localStorage.getItem('resizerPos')
    this.resizerPos = resizerPosLocalStorage ? Number(resizerPosLocalStorage) : null
    this.setOverallVolumeStyle()
    window.addEventListener('resize', this.windowResize)
    window.addEventListener('getBars', (e) => {
      if( !e.detail?.requestParams?.market_tf
        || !e.detail?.requestParams?.resolution
        || (e.detail.requestParams.market_tf === e.detail.requestParams.resolution))
      {
        OverallVolumeClass.setVolumeDelta(e.detail.symbol, e.detail.bars)
      }
    })

    this.propsDropdown = new PropsDropdown(this.tvWidget, {
      hideNotifications: (hide) => {
        this.hideNotifications = hide
        this.drawSignalLine()
      },
      hideSignalsPlot: (hide) => {
        this.hideSignalsPlot = hide
        this.signalsLogUpdate()
      },
      removeSignalsPlot: (pair, removed) => {
        this.signalsLogRemove(removed)
        if(removed.length){
          this.$toastr.success(this.$t('tvPropsDropdown.signalsPlotRemoved', {pair: pair}))
        }else{
          this.$toastr.warning(this.$t('tvPropsDropdown.signalsPlotRemoveNone', {pair: pair}))
        }
      }
    })
    this.hideNotifications = this.propsDropdown.hideNotifications
    this.hideSignalsPlot = this.propsDropdown.hideSignalsPlot
  },
  mounted() {
    if(this.userData.firstLoadDelta) {
      this.$root.$emit('firstLoadDelta')
    }
    this.$root.$on('trendsNotificationCreated', () => {
      this.getSignals()
    })
  },
  computed: {
    ...mapGetters({
      pairs: 'pairs',
      isMobile: 'mediaQuery/isMobile',
      isTablet: 'mediaQuery/isTablet',
      isDesktop: 'mediaQuery/isDesktop',
      userTariffId: 'user/tariff',
      getPermissions: 'user/getPermissions',
      isGuest: 'user/isGuest'
    }),
    userData() {
      return this.$store.state.user.userData
    },
    isProTariff() {
      return this.userTariffId === 'pro'
    },
    isProTrialTariff() {
      return this.userTariffId && this.userTariffId.includes('trial_')
    },
    enabledFeatures() {
      if(this.isGuest){
        return []
      }
      const arr = [
        'study_templates'
      ]

      if (this.userData.getAccess(1)) {
        arr.push('chart_crosshair_menu')
      }

      return arr
    },
    filteredBarsByDate() {
      if (!this.filterDate || !this.filterDate.start || !this.filterDate.end) return this.bars

      const startUnix = this.filterDate.start.getTime()
      const endUnix = this.filterDate.end.getTime()
      return this.bars.filter(item => {
        const date = item.time
        return startUnix <= date && date <= endUnix
      })
    },
    filteredBars() {
      // вычисляемое свойство, обьединяющее фильтрации. На данный момент фильтрация только по дате
      // при увеличении фильтров, применить пересечение массивов
      return this.filteredBarsByDate
    },
    barsLastItem() {
      const length = this.bars.length
      if (!length) return null
      return this.bars[length - 1]
    },
    barsFirstItem() {
      const length = this.bars.length
      if (!length) return null
      return this.bars[0]
    },
    vMoveToTopOptions() {
      if (!this.isDesktop) {
        return {
          position: {bottom: 10, right: 10}
        }
      } else return {
        position: {bottom: 80, right: -35}
      }
    },
    isStudyTrendsEnabled() {
      return this.getPermissions('view_trends')// && this.clientId === 'trends'
    },
    availableTickers() {
      const tickers = []
      Object.keys(symbolsConstants).forEach(item => {
        tickers.push(... symbolsConstants[item].map(symbol => item + ':' + symbol))
      })
      return tickers
    }
  },
  beforeDestroy() {
    window.clearInterval(this.signalTimerId)
    this.$root.$off('trendsNotificationCreated')
  },
  methods: {
    closeEvent() {
      this.showNotificationModal = false
      // setTimeout(() => {
      this.notification = initialNotify.copy()
      // }, 300)
    },
    openModal(notify, isFutures) {
      this.notification = cloneDeep(notify) || initialNotify.copy()
      if (isFutures !== undefined) {
        this.notification.is_futures_init = isFutures
        this.notification.is_futures = isFutures
      }
      this.showNotificationModal = true
    },
    handleAddNotification() {
      this.openModal(null, this.isFuturesForRerender)
    },
    openCreateSignalModal(exchange, symbol, price){
      const pair = this.pairsData.find(el => el.exchange === exchange && el.symbol === symbol)
      let tf = 'price'/* intervalToTf(interval) */
      // if (tf === '1m') {
      //   tf = 'price'
      // } else if (tf === '1w') {
      //   tf = '1d'
      // }
      if (pair && tf) {
        this.notification = new Notification(
          {
            pair,
            conditions: [
              new Condition(
                {
                  tf,
                  var:"price_crossing",
                  // eslint-disable-next-line no-prototype-builtins
                  value: pair.hasOwnProperty('precession') && pair.precession !== null ? price.toFixed(pair.precession) : price
                }
              )
            ]
          }
        )
      }
      this.showNotificationModal = true
    },
    addContextItem(unix, price){
      return [
        {
          position: "bottom",
          text: i18n.t('lk.notifications.createSignal'),
          click: ()=> this.contextCreateSignalHandler(price)
        }
      ];
    },
    contextCreateSignalHandler(price){
      const exchange = this.getExchange()
      const symbol = this.getOnlySymbol()
      this.openCreateSignalModal(exchange, symbol, price)
    },
    onChartReady() {
      //@todo bugfix lib tv v26
      if(this.symbol){
        this.tvWidget.activeChart().setSymbol(this.exchangeSymbol(this.symbol), ()=>{})
      }
      if(this.interval){
        this.tvWidget.activeChart().setResolution(this.interval, ()=>{})
      }

      this.studyVolumeId = this.tvWidget.activeChart().getAllStudies().find(({name}) => name === 'Volume')?.id
      this.studyDeltaId = this.tvWidget.activeChart().getAllStudies().find(({name}) => name === 'Delta')?.id
      /**@todo this.tvWidget.getTheme() возвращает ключ в lowercase, но по коду charting_library использование с заглавной буквы правильное
       * в принципе это условие всегда будет true и его можно убрать, т.к. this.tvWidget._options.theme при инициализации прописывается в widgetOptions
       * штука в том, что сами цвета хранятся в настройках графика, поэтому даже если widgetOptions прописана Dark,
       * а в пришедших настройках (из getInitData или при выборе другого сохранения) остались цвета другой темы, то хоть в виджете и стоит правильная тема,
       * все-равно цвета графика будут от другой, причем this.tvWidget.getTheme() будет выдавать правильную
       * собственно принудительный вызов при инициализации changeTheme видимо и решал эту проблему.
       *
       * несколько решает добавление в watch $store.getters.darkMode принудительного сохранения - т.е. сменили тему -
       * раньше было - в настройки юзера сразу отправляется новая, а в настройки графика с запозданием на 5 сек - когда сработает автосохранение,
       * watch в этом плане форсирует, но получается две отправка - сразу при смене, и затем автосохранение (видимо не сбрасывается какой то параметр, которые проверяется при onAutoSaveNeeded).
       * Аналогично хотел сделать в CandlesGraph, но там график по сути не инициализирован, когда окно не открыто.
       * Другой путь решения - в настройки графика прописывать тему и если не совпадает, то вызывать changeTheme+saveChartToServer
       * т.е. в saveChartAutosave отправлять theme (это я добавил), затем возвращать ее в getChartContentAutosave и в getInitData
       * и уже там проверять совпадает ли с текущей темой
       * думаю theme в ответе апи не нужно помещать в data, а рядом с ним, т.к. в getChartContentAutosave data возвращается текстом, т.е. {status: , data: , theme: }
       * в saveChartAutosave отправку theme добавил, но без бека - там нужно добавить в таблицу tv_charts поле и его обработку
       *
       * По поводу CandlesGraph - очень не рационально при каждом открытие модалки запрашивать initData, может их как то прокинуть и сделать общими
       */

      if(!this.initChartTheme || this.$store.getters.theme !== this.initChartTheme){
        const options = { saveSymbol: true, saveInterval: true };
        const template = this.tvWidget.activeChart().createStudyTemplate(options);

        this.tvWidget.changeTheme(this.tvWidget._options.theme)

        this.$nextTick(() => {
          this.tvWidget.activeChart().applyStudyTemplate(template);
        })
      }

      this.tvWidget.activeChart().dataReady(this.dataReady)
      this.tvWidget.activeChart().onSymbolChanged().subscribe(null, e => {
        this.checkTicker()
        this.symbol = e.symbol
      })
      this.tvWidget.activeChart().onIntervalChanged().subscribe(null, interval => {
        this.checkTicker()
        this.interval = interval
        OverallVolumeClass.resetVolumeDelta()
      })
      this.tvWidget.onContextMenu(this.addContextItem);
      this.tvWidget.activeChart().onDataLoaded().subscribe(null, this.exportData, false)
      this.tvWidget.subscribe('onTick', throttle(this.exportData, 1000))
      this.tvWidget.subscribe('mouse_up', this.mouseUp)
      this.tvWidget.activeChart().crossHairMoved().subscribe(null, ({time}) => this.time = time)
      // событие вызывается через (вроде) и не чаще auto_save_delay, после того, как внесены изменения в график,
      // т.е. если на графике ничего не делать, то и дергать не будет
      this.tvWidget.subscribe('onAutoSaveNeeded', () => this.tvWidget.saveChartToServer(null, null, {defaultChartName: 'autosave'}))
      this.tvWidget.subscribe('onPlusClick', (coordinates) => {
        if (!coordinates.symbol || !Array.isArray(this.pairsData)) return
        const arr = coordinates.symbol.split(':')
        const exchange = arr[0]
        const symbol = arr[1]
        const interval = this.tvWidget.activeChart().resolution()
        if (exchange && symbol && interval) {
          this.openCreateSignalModal(exchange, symbol, coordinates.price)
        }
      });
      this.getNotification()
    },
    getNotification() {
      let result = {}
      forIn(Notification.vars, (section) => {
        forIn(section.items, (value, key) => {
          result[key] = value
        })
      })
      this.varsNotification = result
    },
    mouseUp() {
      if (!(
        this.tvWidget.selectedLineTool() === 'cursor' ||
        this.tvWidget.selectedLineTool() === 'arrow_cursor' ||
        this.tvWidget.selectedLineTool() === 'dot'
      )) return
      if (!this.tvWidget.activeChart().selection().isEmpty()) return
      if (!this.time) return

      const select = this.bars.find(item => item.timeTv === this.time)
      this.setSelectedBar(select)
    },
    setExchange() {
      if (!this.exchange || !this.symbol) return

      let str = this.symbol.indexOf(':') === -1 ? this.exchange + ':' + this.symbol : this.symbol

      if(!this.availableTickers.includes(str)){
        str = 'OVERALL:BTCUSD'
      }

      if(!this.isGuest){
        //@todo временное решение перехода на tv v26
        localStorage.setItem('deltaSymbol', str)
      }

      this.tvWidget.activeChart().setSymbol(str)

      this.tvWidget.headerReady().then(() => { // выполнится после того как хедер отрендерится
        if (this.headerBtnList.length) {
          this.headerBtnList.forEach(btn => {
            if(btn.parentNode?.parentNode){
              if(btn.parentNode.parentNode.previousSibling?.className?.includes('separator')){
                btn.parentNode.parentNode.previousSibling.remove()
              }
              btn.parentNode.parentNode.remove()
            }
          })
          this.headerBtnList = []
        }

        const symbolsList = symbolsConstants[this.exchange]
        if (!symbolsList || !symbolsList.length) return

        const symbolName = this.symbol.split(':').pop()

        symbolsList.forEach(symbol => {
          const button = this.tvWidget.createButton()
          button.textContent = symbol
          if (symbolName === symbol) {
            button.style.color = 'var(--tv-color-toolbar-button-text-active,#2196f3)'
          }
          button.addEventListener('click', () => {
            this.headerBtnList.forEach(btn => btn.style.color = '')
            this.symbol = symbol
            button.style.color = 'var(--tv-color-toolbar-button-text-active,#2196f3)'
          })
          this.headerBtnList.push(button)
        })
      })
      this.signalsUpdate()
      this.signalsLogUpdate()
    },
    async getInitData() {
      if (this.initData){
        return this.initData
      }

      if(this.isGuest){
        this.initData = initDataGuestAutosave
        return this.initData
      }

      const result = await axios.get(tvStorageUrl, {
        params: {lastContentJson: 1, client_id: clientId}
      })
      // todo либо получаем последнее сохранение с сервера, либо применяем начальный конфиг (для первого входа юзера)
      if (result.data && result.data.data) {

        const deltaSymbol = this.getDeltaSymbol()

        //@todo bugfix lib tv v26
        if(result.data.data.symbol){
          result.data.data.symbol = deltaSymbol
        }
        if(result.data.data.interval){
          result.data.data.interval = localStorage.getItem('deltaInterval') || '60'
        }

        if(result.data.data.charts && result.data.data.charts[0] && result.data.data.charts[0].panes && result.data.data.charts[0].panes[0]){
          if(result.data.data.charts[0].panes[0].sources && result.data.data.charts[0].panes[0].sources[0] && result.data.data.charts[0].panes[0].sources[0].state) {
            if(result.data.data.charts[0].panes[0].sources[0].state.symbol){
              result.data.data.charts[0].panes[0].sources[0].state.symbol = deltaSymbol
              result.data.data.charts[0].panes[0].sources[0].state.currencyId = this.getCurrencyCode(deltaSymbol)
            }
            if(result.data.data.charts[0].panes[0].sources[0].state.interval){
              result.data.data.charts[0].panes[0].sources[0].state.interval = localStorage.getItem('deltaInterval') || '60'
            }
            if(result.data.data.charts[0].panes[0].sources[0].state.priceAxisProperties){
              result.data.data.charts[0].panes[0].sources[0].state.priceAxisProperties.autoScale = true
            }
          }
          if(result.data.data.charts[0].panes[0].rightAxisesState && result.data.data.charts[0].panes[0].rightAxisesState[0] && result.data.data.charts[0].panes[0].rightAxisesState[0].state){
            result.data.data.charts[0].panes[0].rightAxisesState[0].state.m_isAutoScale = true
          }
        }

        this.initData = result.data.data
        this.initChartTheme = result.data.theme ? result.data.theme.toLowerCase() : null
      } else {
        this.initData = initDataAutosave
      }
    },
    signalsLogUpdate(resp){
      const exchange = this.getExchange()
      const symbol = this.getOnlySymbol()
      const interval = this.getInterval()
      if(resp === undefined || this.hideSignalsPlot){
        Object.keys(this.signalsLogList).forEach(id => {
          if(this.hideSignalsPlot || this.signalsLogList[id].exchange !== exchange || this.signalsLogList[id].symbol !== symbol || this.signalsLogList[id].interval !== interval){
            if(this.signalsLogList[id].shape && this.tvWidget){
              this.tvWidget.activeChart().removeEntity(this.signalsLogList[id].shape, {
                disableUndo: true
              })
              this.signalsLogList[id].shape = null
              delete this.signalsLogList[id].interval
            }
          }
          if(!this.hideSignalsPlot && !this.signalsLogList[id].shape && this.signalsLogList[id].exchange === exchange && this.signalsLogList[id].symbol === symbol){
            this.signalsLogCreateShape(id, 50)
          }
        })
        if(resp === undefined){
          return
        }
      }
      const tmp = resp.symbol.split(':')
      const respExchange = (tmp[0] || exchange).toUpperCase()
      const respSymbol = (tmp[1] || symbol).toUpperCase()
      resp.symbol.split(':')
      resp.data.forEach(item => {
        if(!this.signalsLogList[item.id]){
          this.signalsLogList[item.id] = item
        }
        this.signalsLogList[item.id].exchange = respExchange
        this.signalsLogList[item.id].symbol = respSymbol
        this.signalsLogList[item.id].interval = interval
        if(!this.hideSignalsPlot){
          this.signalsLogCreateShape(item.id)
        }
      })
    },
    signalsLogRemove(ids = []){
      ids.forEach((id) => {
        if(!this.signalsLogList[id]){
          return
        }
        if(this.signalsLogList[id].shape && this.tvWidget){
          this.tvWidget.activeChart().removeEntity(this.signalsLogList[id].shape, {
            disableUndo: true
          })
        }
        delete this.signalsLogList[id]
      })
    },
    signalsLogCreateShape(id, timeout = null){
      setTimeout(() => {
        if(this.hideSignalsPlot){
          return
        }
        if(this.signalsLogList[id] && !this.signalsLogList[id].shape
          && this.signalsLogList[id].exchange === this.getExchange()
          && this.signalsLogList[id].symbol === this.getOnlySymbol())
        {
          try{
            if(this.tvWidget?.activeChart()){
              let icon = this.signalsLogList[id].icon || 0xf0a2
              if(icon === 0xf132){
                icon = 0xf071
              }
              this.signalsLogList[id].shape = this.tvWidget.activeChart().createShape(
                {
                  time: this.signalsLogList[id].time,
                  price: this.signalsLogList[id].price
                },
                {
                  shape: 'icon',
                  lock: true,
                  disableSelection: true,
                  disableSave: true,
                  disableUndo: true,
                  showInObjectsTree: false,
                  zOrder: 'top',
                  overrides: {
                    icon: icon,
                    size: this.signalsLogList[id].size || 19,
                    color: this.signalsLogList[id].color
                  }
                }
              )
            }
          } catch(e){
            console.error(e);
          }
          if(this.signalsLogList[id].shape){
            this.signalsLogList[id].interval = this.getInterval()
          } else if(timeout < 500) {
            this.signalsLogCreateShape(id, timeout === null ? 100 : timeout + 100)
          }
        }
      }, timeout)
    },
    signalsUpdate(resp) {
      const exchange = this.getExchange()
      const symbol = this.getOnlySymbol()
      if(resp === undefined){
        this.activeSignal = this.activeSignals[exchange + ':' + symbol] || []
        return
      }
      let result = []
      resp.filter(item => item.id && item.conditions && item.pair && item.pair.exchange === exchange && item.pair.symbol === symbol).forEach(el => {
        let k = 0;
        el.conditions.forEach(element => {
          result.push(Object.assign(element, {
            is_single: el.is_single,
            signalId: Number(el.id),
            comment: el.comment,
            k: k
          }))
          k++
        })
      })
      this.activeSignal = result
      this.activeSignals[exchange + ':' + symbol] = this.activeSignal
    },
    removeSignalsPlotCallback(){
      this.propsDropdown.removeSignalsPlotCallback()
    },
    async getSignals() {
      if(!this.signalLoading){
        const exchange = this.getExchange()
        const onlySymbol = this.getOnlySymbol()
        if(!exchange || !onlySymbol){
          return;
        }
        this.signalLoading = true
        const res = await axios.get('api/v1/tv_signals', {
          params: {
            tv: 1,
            exchange: exchange,
            symbol: onlySymbol
          }
        })
        if(res?.status < 300){
          this.signalsUpdate(res?.data?.data || [])
        }
        this.signalLoading = false
      }
    },
    async editSignal(el) {
      const res = await axios.get('api/v1/group_by_signal_id/' + el.signalId)
      if(!res?.data?.data){
        return
      }
      this.notification = new Notification({...res.data.data, editMode: true})
      this.showNotificationModal = true
    },
    dataReady() { // первая загрузка свечей
      this.firstDataLoaded = true
      this.interval = this.tvWidget.symbolInterval().interval

      this.checkTicker()

      // todo по id смотрю начальный это конфиг или загруженный, если начальный,
      // значит еще нет сохранений и надо сразу сохранить, чтобы в дальнейшем дублей не возникло
      if (!this.initData.id) {
        this.tvWidget.saveChartToServer(null, null, {
          defaultChartName: 'autosave'
        })
      }

      // если нужно обновить начальный конфиг initData, комментим лишнее, раскомменчиваем нужное, выведит конфиг в консоль,
      // в него в корень только нужно будет добавить symbol и interval
      //this.tvWidget.save((state) => {
      //  console.log(JSON.stringify(state))
      //})
    },
    checkTicker() {
      const symbolExt = this.tvWidget.activeChart().symbolExt()
      let ticker = symbolExt ? (symbolExt.exchange + ':' + symbolExt.symbol) : this.getDeltaSymbol()
      if(!this.availableTickers.includes(ticker)){
        ticker = this.getDeltaSymbol()
        const tmp = ticker.split(':')
        this.exchange = tmp[0]
        this.symbol = tmp[1]
      }else{
        this.exchange = symbolExt.exchange
        this.symbol = symbolExt.symbol
      }
    },
    firstRayDrawing() {
      const length = this.bars.length
      if (!length) return

      const select = this.bars[length - 1]
      if (!select) return
      this.setSelectedBar(select)
    },
    exportData() {
      setTimeout(() => {
        this.tvWidget.activeChart().exportData()
          .then(({data, schema}) => {
            this.setBars(data, schema)
            this.setVolumeGroupStyle()
            if (!this.firstRayDrawed) {
              this.firstRayDrawing()
              this.firstRayDrawed = true
            }
          })
          .catch(err => {
            console.error(err)
          })
      })
    },
    setBars(arr, schema) {
      const str = this.symbol.indexOf(':') === -1 ? this.exchange + ':' + this.symbol : this.symbol
      this.bars = OverallVolumeClass.formatAll(arr, schema, str)

      if (this.selectedBar) {
        const selected = this.bars.find(v => v.time === this.selectedBar.time)
        if (!selected) return
        this.selectedBar = selected
        this.selectedBar?.highlight()
      }
    },
    setSelectedBar(bar) {
      if (this.rayId) this.tvWidget.activeChart().removeEntity(this.rayId, {disableUndo: true})

      if (this.selectedBar) {
        this.selectedBar.removeHighlight()
        this.selectedBar = null
      }

      this.selectedBar = bar
      this.selectedBar?.highlight()
      this.rayDrawing()
    },
    rayDrawing() {
      if (!this.selectedBar) return

      const {time, start, end} = this.selectedBar.selectedParams
      if (!time || !start || !end) return

      const drawRay = () => {
        this.rayId = this.tvWidget.activeChart().createMultipointShape(
          [{time, price: end}, {time, price: end * 1.1}],
          {
            shape: "ray",
            // lock: true,
            disableSelection: true,
            disableSave: true,
            disableUndo: true,
            showInObjectsTree: false,
            overrides: {
              linestyle: 2
            }
          }
        )
      }

      const {from: rangeFrom, to: rangeTo} = this.tvWidget.activeChart().getVisibleRange()
      if ((time > rangeFrom && time < rangeTo) || !this.enableNavigation) {
        drawRay()
      } else {
        const rangeDelta = rangeTo - rangeFrom
        const rangeDeltaHalf = rangeDelta / 2
        this.tvWidget.activeChart().setVisibleRange({
          from: time - rangeDeltaHalf,
          to: time + rangeDeltaHalf
        })
          .then(drawRay)
      }
    },
    deleteDraw() {
      this.showConfirmModal = false
      axios.delete('api/v1/signals', {
        data: {
          id: this.dataConfirmModal.signalId
        }
      }).then(() => {
        this.$toastr.success(this.$t('lk.notifications.removeSuccess'))
        if(this.drawnLines[this.dataConfirmModal.signalId]){
          Object.keys(this.drawnLines[this.dataConfirmModal.signalId]).forEach(id => this.drawnLines[this.dataConfirmModal.signalId][id]._line && this.drawnLines[this.dataConfirmModal.signalId][id].remove())
          delete this.drawnLines[this.dataConfirmModal.signalId]
        }
      }).catch((e) => {
        if(e.response.status === 404){
          if(this.drawnLines[this.dataConfirmModal.signalId]){
            Object.keys(this.drawnLines[this.dataConfirmModal.signalId]).forEach(id => this.drawnLines[this.dataConfirmModal.signalId][id]._line && this.drawnLines[this.dataConfirmModal.signalId][id].remove())
            delete this.drawnLines[this.dataConfirmModal.signalId]
          }
        }
      })
    },
    drawSignalLine() {
      try{
        Object.keys(this.drawnLines).forEach(el => {
          el = Number(el)
          if(this.hideNotifications || !this.activeSignal.some(item => item.signalId === el)) {
            let isDelete = true
            Object.keys(this.drawnLines[el]).forEach(id => {
              if(this.drawnLines[el][id].tmp_position){
                isDelete = false
              }else if(this.drawnLines[el][id]._line){
                this.drawnLines[el][id].remove()
              }
            })
            if(isDelete){
              delete this.drawnLines[el]
            }
          }
        })
        // eslint-disable-next-line no-empty
      }catch(e){

      }

      if(this.hideNotifications){
        return
      }
      if(!this.tvWidget || !this.tvWidget.activeChart()){
        return
      }

      this.activeSignal.forEach(el => {
        if(!this.drawnLines[el.signalId]){
          this.drawnLines[el.signalId] = {}
        }
        try{
          if(!this.drawnLines[el.signalId][el.k]) {
            this.drawnLines[el.signalId][el.k] = this.tvWidget.activeChart().createOrderLine()
              .setPrice(el.position)
              .setText(`${el.tf} ${this.varsNotification[el.var]} ${el.value}`)
              .setQuantity('✏️')
              .onModify(() => {
                this.editSignal(el)
              })
              .setTooltip(el.comment || '')
              .setCancelTooltip(this.$t('lk.notifications.deleteNotification'))
            if(el.is_single){
              this.drawnLines[el.signalId][el.k]
                .onCancel(() => {
                this.showConfirmModal = true
                this.dataConfirmModal = el
              })
                .onMove(async () => {
                  this.drawnLines[el.signalId][el.k].tmp_position = this.drawnLines[el.signalId][el.k].getPrice()
                  this.drawnLines[el.signalId][el.k].setEditable(false)
                  try {
                    const res = await axios.post('api/v1/signals/change_position/' + el.signalId, {
                      k: el.k,
                      position: this.drawnLines[el.signalId][el.k].tmp_position
                    })
                    if(res?.data?.success){
                      this.$toastr.success(res?.data?.message || this.$t('lk.notifications.signalChanged'))
                    }else if(res?.data?.message) {
                      this.$toastr.warning(res.data.message)
                    }
                    await this.getSignals()
                    // eslint-disable-next-line no-empty
                  } catch(e){}
                  this.drawnLines[el.signalId][el.k].setEditable(true)
                  delete this.drawnLines[el.signalId][el.k].tmp_position
                })
            }

            if(el.tf === 'price'){
              this.drawnLines[el.signalId][el.k]
                .setLineColor("rgb(150, 100, 200)")
                .setCancelButtonIconColor("rgb(150, 100, 200)")
                .setBodyBorderColor("rgb(150, 100, 200)")
                .setCancelButtonBorderColor("rgb(150, 100, 200)")
                .setBodyTextColor("rgb(150, 100, 200)")
                .setBodyBackgroundColor("rgba(150, 100, 200, 0.25)")
                .setCancelButtonBackgroundColor("rgba(120, 80, 180, 0.4)")
                .setQuantityBackgroundColor("rgb(150, 100, 200, 0.25)")
                .setQuantityBorderColor("rgb(150, 100, 200)")
            }else{
              this.drawnLines[el.signalId][el.k]
                .setQuantityBackgroundColor("rgba(255, 255, 255, 0.75)")
            }
          }else{
            this.drawnLines[el.signalId][el.k]
              .setPrice(this.drawnLines[el.signalId][el.k].tmp_position || el.position)
              .setText(`${el.tf} ${this.varsNotification[el.var]} ${el.value}`)
              .setTooltip(el.comment || '')
          }
          // eslint-disable-next-line no-empty
        }catch(e){}
      })
    },
    resizerStart() {
      const move = throttle(e => {
        this.resizerPos = e.clientX
      }, 50)

      this.resizerMoving = true
      document.addEventListener('mousemove', move)
      document.addEventListener('mouseup', () => {
        document.removeEventListener('mousemove', move)
        this.resizerMoving = false
        localStorage.setItem('resizerPos', this.resizerPos)
      }, {once: true})
    },
    setVolumeGroupStyle() {
      const x = this.resizerPos
      if (!x) return {}

      const el = this.$refs.volumeGroup
      if (!el) return {}

      const oldWidth = el.getBoundingClientRect().width
      const left = el.getBoundingClientRect().left
      const diff = left - x
      const resizerPaddingLeft = 8
      this.volumeGroupStyle = {
        width: oldWidth + diff - resizerPaddingLeft + 'px'
      }
    },
    setOverallVolumeStyle() {
      const volumeGroup = this.isDesktop || this.isMobile ? this.$refs.cardBody : this.$refs.marketVolume?.$el
      if (!volumeGroup) return
      const height = volumeGroup.getBoundingClientRect().height
      this.overallVolumeStyle = {
        height: height + 'px'
      }

      this.tvStyle = {
        maxHeight: height + 'px'
      }
    },
    windowResize() {
      this.setOverallVolumeStyle()
    },
    getExchange() {
      let exchange = null
      try{
        exchange = this.exchange || this.tvWidget.activeChart().symbolExt().exchange
        // eslint-disable-next-line no-empty
      }catch(e){}
      return exchange ? exchange.toUpperCase() : ''
    },
    getSymbol() {
      try{
        return this.symbol || this.tvWidget.activeChart().symbolExt().symbol
        // eslint-disable-next-line no-empty
      }catch(e){}
      return ''
    },
    getInterval() {
      try{
        return this.interval || this.tvWidget?.symbolInterval()?.interval || this.initData?.interval || this.initData?.resolution || '60'
        // eslint-disable-next-line no-empty
      }catch(e){}
      return ''
    },
    getOnlySymbol() {
      let symbol = null
      try{
        symbol = this.symbol || this.tvWidget.activeChart().symbolExt().symbol
        // eslint-disable-next-line no-empty
      }catch($e){}
      return symbol ? symbol.split(':').pop().toUpperCase() : ''
    },
    getDeltaSymbol() {
      let deltaSymbol = !this.isGuest && localStorage.getItem('deltaSymbol') || 'OVERALL:BTCUSD'
      if(!this.availableTickers.includes(deltaSymbol)){
        deltaSymbol = 'OVERALL:BTCUSD'
      }
      return deltaSymbol
    },
    getCurrencyCode(symbol) {
      return ['USDT', 'USDC', 'TUSD', 'USD'].find(c => (new RegExp(c + '$')).test(symbol)) || 'USDT'
    },
  },
  watch: {
    isFuturesForRerender() {
      this.closeEvent()
      this.$nextTick(() => {
        this.handleAddNotification()
        this.showNotificationKey++
      })
    },
    symbol() {
      if(!this.toSetExchange){
        this.toSetExchange = setTimeout(() => {
          this.setExchange()
          this.toSetExchange = null
        })
      }
    },
    exchange() {
      if(!this.toSetExchange){
        this.toSetExchange = setTimeout(() => {
          this.setExchange()
          this.toSetExchange = null
        })
      }
    },
    interval() {
      if(!this.toSetInterval){
        this.signalsLogRemove(Object.keys(this.signalsLogList))
        this.toSetInterval = setTimeout(() => {
          //@todo временное решение перехода на tv v26
          localStorage.setItem('deltaInterval', this.interval)
          this.toSetInterval = null
        })
      }
    },
    activeSignal() {
      setTimeout(() => {
        this.drawSignalLine()
      })
    },
    '$store.getters.darkMode': function (val) {
      const options = { saveSymbol: true, saveInterval: true };
      const template = this.tvWidget.activeChart().createStudyTemplate(options);

      this.tvWidget.changeTheme(val ? 'Dark' : 'Light')

      this.$nextTick(() => {
        this.tvWidget.activeChart().applyStudyTemplate(template);
      })
    },
    resizerPos() {
      this.setVolumeGroupStyle()
    },
    firstDataLoaded() {
      this.$nextTick(this.setOverallVolumeStyle)
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.windowResize)
    if (this.tvWidget !== null) {
      this.tvWidget.remove();
      this.tvWidget = null;
    }
    clearInterval(this.signalTimerId);
  }
}
</script>

<style lang="scss" scoped>

.volume-group {
  &__spinner {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .spinner {
      width: 4rem;
      height: 4rem;
    }

    @include media-breakpoint-up(xl) {
      height: 200%;
    }
  }

  @include media-breakpoint-down(lg) {
    width: 100% !important;
    overflow: hidden;
    height: 100%;
  }

  @include media-breakpoint-down(sm) {
    height: unset;
  }

  @include media-breakpoint-up(xl) {
    max-width: 80%;
  }

  .volume-group-content {
    @include media-breakpoint-down(lg) {
      height: 100%;
    }

    @include media-breakpoint-down(sm) {
      height: unset;
    }
  }
}

.resizer {
  padding: 0.5rem;
  height: 100%;
  user-select: none;
  cursor: col-resize;

  &__inner {
    height: 100%;
    background: var(--dark);
    width: 2px;
    transition: all 250ms;

    &._move {
      background: var(--primary);
      box-shadow: 0 0 5px 1px var(--primary);
    }
  }

  &:hover {
    .resizer__inner {
      background: var(--primary);
      box-shadow: 0 0 5px 1px var(--primary);
    }
  }
}

.transparent-overlay {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.card-body {
  overflow-y: hidden;
  @include media-breakpoint-down(sm) {
    overflow-y: hidden;
  }

  @include media-breakpoint-down(md) {
    padding: 0;
  }

  @include media-breakpoint-down(lg) {
    overflow-y: auto;
  }
}

.card-body-content {
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-wrap: wrap;

  /deep/ #move-to-top-arrow {
    @include media-breakpoint-down(lg) {
      display: none !important;
    }
  }

  @include media-breakpoint-between(sm, md) {
    padding-top: 1rem;
  }

  @include media-breakpoint-up(xl) {
    display: grid;
    grid-template-columns: minmax(20%, 1fr) minmax(20%, auto);
    row-gap: 1.5rem;
  }
}

.select-date-mobile {
  margin-left: auto;
  margin-right: 0;
  width: 100%;

  @include media-breakpoint-up(md) {
    margin-left: .6875rem;
    margin-right: 0;
  }

  @include media-breakpoint-up(xl) {
    margin-right: 1.3125rem;
  }

  @include media-breakpoint-down(lg) {
    /deep/ {
      .input-group {
        .input-group-prepend {
          .input-group-text {
            border-radius: .1875rem 0 0 .1875rem;
          }
        }

        input.form-control {
          border-radius: 0;
        }

        .input-group-append {
          border-radius: 0 .1875rem .1875rem 0;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    /deep/ {
      .input-group {
        flex-direction: row-reverse;

        .input-group-prepend {
          .input-group-text {
            border-radius: 0 .1875rem .1875rem 0;
          }
        }

        input.form-control {
          border-radius: 0;
        }

        .input-group-append {
          border-radius: .1875rem 0 0 .1875rem;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    /deep/ {
      margin-left: 1rem;
      margin-right: 1rem;

      .input-group {
        .input-group-prepend {
          .input-group-text {
            padding-top: 0;
            padding-bottom: 0;
          }
        }

        input.form-control {
          height: 1.25rem;
          margin-left: 0;
        }
      }
    }
  }

  @include media-breakpoint-down(xs) {
    margin-left: .25rem;
    margin-right: .25rem;
    /deep/ {
      .input-group {
        .input-group-prepend {
          .input-group-text {
            padding: 0 .5rem;
          }
        }

        input.form-control {
          margin-left: 0;
          padding-left: .25rem;
          padding-right: .25rem;
        }
      }
    }
  }
}

.tv_chart_wrap {
  display: flex;
  height: 100%;
  width: 100%;
  margin-bottom: .625rem;
  margin-top: .625rem;
  order: 1;

  @include media-breakpoint-down(lg) {
    max-height: unset !important;
    order: 2;
  }

  @include media-breakpoint-up(md) {
    margin-top: .625rem;
    margin-bottom: 0;
  }

  @include media-breakpoint-up(xl) {
    position: sticky;
    top: 0;
    grid-row: 1 / 3;
    margin-top: 0;
    order: 0;
  }
}

.market-volume {
  height: 100%;

  &._is-loaded {
    height: auto;
    max-height: 100%;
  }

  @include media-breakpoint-down(lg) {
    width: 100% !important;
    overflow: hidden;
  }

  @include media-breakpoint-up(md) {
    margin-right: .625rem;
    order: 0;

    &._is-loaded {
      max-width: calc(52% - .625rem / 2);
    }
  }

  @include media-breakpoint-up(lg) {
    &._is-loaded {
      max-width: calc(50% - .625rem / 2);
    }
  }

  @include media-breakpoint-up(xl) {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    max-width: 100%;
    min-width: 100%;
    margin-right: 0;
    order: 1;
  }
}

.overall-volume-wrap {
  order: 2;

  @include media-breakpoint-down(sm) {
    &._minimized {
      height: fit-content !important;
    }
  }

  @include media-breakpoint-down(lg) {
    width: 100% !important;
    overflow: hidden;
    max-height: 100%;
    height: auto;
  }

  @include media-breakpoint-up(md) {
    max-width: calc(48% - .625rem / 2);
    order: 1;
  }

  @include media-breakpoint-up(lg) {
    max-width: calc(50% - .625rem / 2);
  }

  @include media-breakpoint-up(xl) {
    grid-column: 2 / 3;
    max-width: 100%;
    min-width: 100%;
    order: 2;
  }
}
</style>
