var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCard",
    {
      staticClass: "card shadow-none overflow-hidden",
      class: { _minimized: !_vm.showAll && _vm.isMobile },
    },
    [
      _c(
        "CCardHeader",
        { staticClass: "card-header bg-dark d-flex align-items-center" },
        [
          _c("span", [_vm._v("Overall Volume")]),
          _vm._t("header"),
          _c(
            "label",
            {
              staticClass: "ml-auto mb-0 col-form-label-sm",
              on: {
                click: function ($event) {
                  _vm.isRsi = _vm.changeEnableNavigation
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("delta.nav")))]
          ),
          _vm.isDesktop
            ? _c("CSwitch", {
                staticClass: "mx-2 switch-custom",
                attrs: {
                  color: "primary",
                  shape: "pill",
                  checked: _vm.enableNavigation,
                },
                on: { "update:checked": _vm.changeEnableNavigation },
              })
            : _vm._e(),
          _vm.isDesktop
            ? _c(
                "span",
                {
                  directives: [
                    {
                      name: "c-tooltip",
                      rawName: "v-c-tooltip",
                      value: {
                        header: _vm.$t("delta.nav"),
                        content: _vm.$t("delta.navDescription"),
                        appendToBody: true,
                        popperOptions: {
                          strategy: "fixed",
                        },
                      },
                      expression:
                        "{\n        header: $t('delta.nav'),\n        content: $t('delta.navDescription'),\n        appendToBody: true,\n        popperOptions: {\n          strategy: 'fixed'\n        }\n      }",
                    },
                  ],
                  staticClass:
                    "nav-description rounded-circle bg-second-card-bg popover-wrap d-inline-flex justify-content-center flex-shrink-0",
                },
                [_vm._v(" ? ")]
              )
            : _vm._e(),
        ],
        2
      ),
      _c("CCardBody", { staticClass: "card-body overflow-hidden pb-0" }, [
        _c(
          "div",
          { staticClass: "table-wrap" },
          [
            _c("table", { staticClass: "table" }, [
              _c("thead", [
                _c(
                  "tr",
                  _vm._l(_vm.fields, function (field, i) {
                    return _c("th", { key: i }, [
                      _vm._v(" " + _vm._s(field.label) + " "),
                    ])
                  }),
                  0
                ),
              ]),
              _c(
                "tbody",
                _vm._l(_vm.reverseBars, function (item, i) {
                  return _c(
                    "tr",
                    {
                      key: i,
                      class: item._classes,
                      on: {
                        click: function ($event) {
                          return _vm.rowClick(item)
                        },
                      },
                    },
                    [
                      _c("td", { class: item._cellClasses.timeStr }, [
                        _vm._v(_vm._s(item.timeStr)),
                      ]),
                      _c("td", { class: item._cellClasses.delta }, [
                        _vm._v(_vm._s(item.delta)),
                      ]),
                      _c("td", { class: item._cellClasses.volume }, [
                        _vm._v(_vm._s(item.volume)),
                      ]),
                      _c("td", { class: item._cellClasses.open }, [
                        _vm._v(_vm._s(Math.floor(item.open * 100) / 100)),
                      ]),
                      _c("td", { class: item._cellClasses.close }, [
                        _vm._v(_vm._s(Math.floor(item.close * 100) / 100)),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]),
            _c("transition", { attrs: { name: "table-show-all" } }, [
              _vm.isMobile && !_vm.showAll
                ? _c("div", { staticClass: "table-show-all" }, [
                    _c(
                      "button",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.$emit("update:showAll", true)
                          },
                        },
                      },
                      [
                        _vm._v(" " + _vm._s(_vm.$t("delta.expand")) + " "),
                        _c("CIcon", {
                          staticClass: "arrow-bottom ml-1",
                          attrs: { name: "arrow-trends", width: "0.6rem" },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }