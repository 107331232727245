var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-date-picker", {
        attrs: {
          mode: "dateTime",
          value: _vm.value,
          "is-dark": _vm.$store.getters.darkMode,
          "is-range": "",
          is24hr: "",
          masks: {
            input: "DD/MM/YY HH:mm",
          },
          "min-date": _vm.firstItemDate,
          "max-date": _vm.lastItemDate,
          timezone: "utc",
          locale: _vm.$root.$i18n.locale,
          popover: {
            positionFixed: true,
            visibility: _vm.isMobileGetter ? "hidden" : "hover-focus",
          },
        },
        on: {
          input: function ($event) {
            return _vm.changeValue($event)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ inputValue, inputEvents }) {
              return [
                _c(
                  "CInput",
                  _vm._g(
                    {
                      staticClass: "input",
                      attrs: {
                        value: _vm.isDesktop
                          ? _vm.inputValueStr(inputValue)
                          : " ",
                        placeholder: _vm.isDesktop
                          ? _vm.$t("delta.datePlaceholder")
                          : " ",
                        readonly: "",
                      },
                      on: {
                        click: function ($event) {
                          _vm.isMobileGetter ? (_vm.showModal = true) : null
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "prepend-content",
                            fn: function () {
                              return [
                                inputValue.start
                                  ? _c("CIcon", {
                                      staticClass: "text-danger",
                                      attrs: { name: "cil-x" },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.changeValue(null)
                                        },
                                      },
                                    })
                                  : _c("CIcon", {
                                      attrs: { name: "cil-calendar" },
                                    }),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "append",
                            fn: function () {
                              return [
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "c-tooltip",
                                        rawName: "v-c-tooltip",
                                        value: {
                                          content: _vm.$t("delta.quoteDate"),
                                          appendToBody: true,
                                          popperOptions: {
                                            strategy: "fixed",
                                          },
                                        },
                                        expression:
                                          "{\n              content: $t('delta.quoteDate'),\n              appendToBody: true,\n              popperOptions: {\n                strategy: 'fixed'\n              }\n            }",
                                      },
                                    ],
                                    staticClass:
                                      "nav-description rounded-circle popover-wrap d-inline-flex justify-content-center flex-shrink-0",
                                  },
                                  [_vm._v(" ? ")]
                                ),
                                !_vm.isDesktop
                                  ? _c(
                                      "div",
                                      { staticClass: "label-after-input" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.inputValueStr(inputValue)
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    inputEvents.start
                  )
                ),
              ]
            },
          },
        ]),
      }),
      _vm.isMobileGetter
        ? _c("calendar-modal", {
            attrs: {
              "modal-title": _vm.inputValueStr(),
              show: _vm.showModal,
              value: _vm.value,
              "calendar-props": {
                "min-date": _vm.firstItemDate,
                "max-date": _vm.lastItemDate,
                mode: "dateTime",
                "is-range": true,
                is24hr: true,
                masks: {
                  input: "DD/MM/YY HH:mm",
                },
                timezone: "utc",
              },
            },
            on: {
              save: function ($event) {
                return _vm.changeValue($event)
              },
              closeModal: function ($event) {
                _vm.showModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }