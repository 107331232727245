<template>
  <div>
    <v-date-picker
      mode="dateTime"
      :value="value"
      :is-dark="$store.getters.darkMode"
      is-range
      is24hr
      :masks="{
        input: 'DD/MM/YY HH:mm',
      }"
      :min-date="firstItemDate"
      :max-date="lastItemDate"
      timezone="utc"
      :locale="$root.$i18n.locale"
      :popover="{
        positionFixed: true,
        visibility: isMobileGetter ? 'hidden' : 'hover-focus'
      }"
      @input="changeValue($event)"
    >
      <template v-slot="{ inputValue, inputEvents }">
        <CInput
          :value="isDesktop ? inputValueStr(inputValue) : ' '"
          :placeholder="isDesktop ? $t('delta.datePlaceholder') : ' '"
          readonly
          class="input"
          @click="isMobileGetter ? showModal = true : null"
          v-on="inputEvents.start"
        >
          <template #prepend-content>
            <CIcon
              v-if="inputValue.start"
              name="cil-x"
              class="text-danger"
              @click.native="changeValue(null)"
            />
            <CIcon v-else name="cil-calendar"/>
          </template>
          <template #append>
            <span
              class="nav-description rounded-circle popover-wrap d-inline-flex justify-content-center flex-shrink-0"
              v-c-tooltip="{
                content: $t('delta.quoteDate'),
                appendToBody: true,
                popperOptions: {
                  strategy: 'fixed'
                }
              }"
            >
              ?
            </span>
            <!-- Добавлено для устранения увеличения масштаба страницы на iso: если размер шрифта у input меньше 16px -
      ios увеличивает масштаб страницы при фокусировке поля ввода      -->
            <div v-if="!isDesktop" class="label-after-input">
              {{ inputValueStr(inputValue) }}
            </div>
          </template>
        </CInput>
      </template>
    </v-date-picker>

    <calendar-modal
      v-if="isMobileGetter"
      :modal-title="inputValueStr()"
      :show="showModal"
      :value="value"
      :calendar-props="{
        'min-date': firstItemDate,
        'max-date': lastItemDate,
        mode: 'dateTime',
        'is-range': true,
        is24hr: true,
        masks: {
          input: 'DD/MM/YY HH:mm',
        },
        timezone: 'utc'
      }"
      @save="changeValue($event)"
      @closeModal="showModal = false"
    />
  </div>
</template>

<script>
import {DatePicker} from 'v-calendar'
import CalendarModal from "@/components/common/CalendarModal";
import {mapGetters} from "vuex";

export default {
  name: "SelectDate",
  components: {
    'v-date-picker': DatePicker,
    CalendarModal,
  },
  props: {
    value: Object,
    firstBar: {
      type: Object,
      default: () => null
    },
    lastBar: {
      type: Object,
      default: () => null
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showModal: false,
    }
  },
  computed: {
    ...mapGetters({
      isMobileGetter: 'mediaQuery/isMobile',
      isDesktop: 'mediaQuery/isDesktop',
      isLaptop: 'mediaQuery/isLaptop',
    }),
    firstItemDate() {
      if (!this.firstBar?.time) return null
      return this.firstBar.time
    },
    lastItemDate() {
      if (!this.lastBar?.time) return null
      return this.lastBar.time
    }
  },
  methods: {
    changeValue(v) {
      this.$emit('input', v)
      // dispatch('userSettings/setKey', {
      //   namespace: 'overallVolume',
      //   key: 'filterDate',
      //   value: payload
      // }, {root: true})
    },
    inputValueStr(value) {
      if (
        value &&
        value.start &&
        value.end
      ) {
        return value.start + ' ➜ ' + value.end
      } else {
        return this.isMobile ? this.$t('delta.datePlaceholderMobile') : this.$t('delta.datePlaceholder')
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.text-danger {
  cursor: pointer;
}

.input {
  /deep/ .form-control[readonly],
  /deep/ .input-group-append {
    background-color: #ffffff
  }

  @include media-breakpoint-down(lg) {
    margin: 0;

    /deep/ input {
      font-size: 1rem;
    }
  }

  @include media-breakpoint-down(md) {

    /deep/ .c-icon {
      width: .6875rem !important;
      height: .6875rem !important;
    }
  }

  @include media-breakpoint-only(md) {
    /deep/ input {
      height: 2rem;
    }
  }

  @include media-breakpoint-only(lg) {
    /deep/ input {
      height: 2.1875rem;
    }
  }

  .label-after-input {
    position: absolute;
    top: 0;
    bottom: 0;
    // right: .75rem;
    left: 3.375rem;
    margin: auto;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    pointer-events: none;
    user-select: none;
    overflow: hidden;
    width: calc(100% - 6rem);
    line-height: 1;

    @include media-breakpoint-down(md) {
      font-size: 0.75rem;
      left: 2.5rem;
      width: calc(100% - 5rem);
      right: auto;
    }

    @include media-breakpoint-down(xs) {
      left: 1.75rem;
      width: calc(100% - 3.6rem);
      right: auto;
    }
  }

  /deep/ .input-group-append {
    border: 1px solid #e4e7ea;
    border-radius: 0 .25rem .25rem 0;
  }
}

.c-dark-theme {
  .input {
    /deep/ .form-control[readonly],
    /deep/ .input-group-append {
      background-color: rgba(255, 255, 255, 0.05);
    }

    /deep/ .input-group-prepend {
      @include media-breakpoint-down(md) {
        background: var(--dark);
      }
    }

    /deep/ .input-group-append {
      border-color: rgba(255, 255, 255, 0.09);
    }
  }

  .popover-wrap {
    background: #333440;

    @include media-breakpoint-up(xl) {
      background: #636F83;
    }
  }
}

.popover-wrap {
  cursor: pointer;
  display: inline-flex;
  width: 1rem;
  height: 1rem;
  font-size: 0.75rem;
  align-self: center;
  margin-left: .25rem;
  margin-right: .25rem;
  background: #e5e5e5;

  @include media-breakpoint-up(md) {
    margin-left: .5rem;
    margin-right: .5rem;
  }
}
</style>
