var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCard",
    { staticClass: "card h-100 mb-0" },
    [
      _c(
        "CCardBody",
        { staticClass: "card-body" },
        [
          _c("custom-scroll", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "move-to-top",
                    rawName: "v-move-to-top",
                    value: _vm.vMoveToTopOptions,
                    expression: "vMoveToTopOptions",
                  },
                ],
                ref: "cardBody",
                staticClass: "card-body-content no-gutters",
                attrs: { id: "volume-group" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "tv_chart_wrap",
                    style: _vm.tvStyle,
                    attrs: { "data-onboarding-step": "3/13/14" },
                  },
                  [
                    _c("div", {
                      staticClass: "h-100 w-100",
                      class: {
                        "border-second-card-bg": _vm.$store.getters.darkMode,
                        "border-dark": !_vm.$store.getters.darkMode,
                        "transparent-overlay": _vm.resizerMoving,
                      },
                      attrs: { id: "tv_chart_container" },
                    }),
                    _vm.isDesktop
                      ? _c(
                          "div",
                          {
                            staticClass: "resizer",
                            on: { mousedown: _vm.resizerStart },
                          },
                          [
                            _c("div", {
                              staticClass: "resizer__inner",
                              class: _vm.resizerMoving ? "_move" : "",
                            }),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
                _c(
                  "div",
                  {
                    ref: "volumeGroup",
                    staticClass: "market-volume",
                    class: { "_is-loaded": _vm.firstDataLoaded },
                    style: _vm.volumeGroupStyle,
                    attrs: { "data-onboarding-step": "4" },
                  },
                  [
                    _vm.firstDataLoaded && _vm.isDesktop
                      ? _c("select-date", {
                          attrs: {
                            "first-bar": _vm.barsFirstItem,
                            "last-bar": _vm.barsLastItem,
                            "data-onboarding-step": "10",
                          },
                          model: {
                            value: _vm.filterDate,
                            callback: function ($$v) {
                              _vm.filterDate = $$v
                            },
                            expression: "filterDate",
                          },
                        })
                      : _vm._e(),
                    _vm.firstDataLoaded
                      ? _c("market-volume", {
                          ref: "marketVolume",
                          attrs: {
                            "ext-data": _vm.marketData,
                            "filter-date": _vm.filterDate,
                            interval: _vm.interval,
                            "filtered-bars": _vm.filteredBars,
                            exchange: _vm.exchange,
                            symbol: _vm.symbol,
                          },
                          on: {
                            "change-exchange": function ($event) {
                              _vm.exchange = _vm.isGuest ? _vm.exchange : $event
                            },
                            "change-symbol": function ($event) {
                              _vm.symbol = _vm.isGuest ? _vm.symbol : $event
                            },
                            "data-loaded": _vm.setOverallVolumeStyle,
                          },
                          scopedSlots: _vm._u(
                            [
                              _vm.isMobile
                                ? {
                                    key: "header",
                                    fn: function () {
                                      return [
                                        _c("select-date", {
                                          staticClass: "select-date-mobile",
                                          attrs: {
                                            "first-bar": _vm.barsFirstItem,
                                            "last-bar": _vm.barsLastItem,
                                            "is-mobile": "",
                                          },
                                          model: {
                                            value: _vm.filterDate,
                                            callback: function ($$v) {
                                              _vm.filterDate = $$v
                                            },
                                            expression: "filterDate",
                                          },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  }
                                : null,
                            ],
                            null,
                            true
                          ),
                        })
                      : _c(
                          "div",
                          { staticClass: "volume-group__spinner" },
                          [
                            _c("CSpinner", {
                              staticClass: "spinner",
                              attrs: { color: "primary" },
                            }),
                          ],
                          1
                        ),
                  ],
                  1
                ),
                _vm.firstDataLoaded
                  ? _c(
                      "div",
                      {
                        staticClass: "overall-volume-wrap",
                        class: {
                          _minimized: !_vm.showAllOverallVolume && _vm.isMobile,
                        },
                        style: _vm.volumeGroupStyle,
                      },
                      [
                        _c("overall-volume", {
                          style: _vm.overallVolumeStyle,
                          attrs: {
                            "filtered-bars": _vm.filteredBars,
                            "selected-bar": _vm.selectedBar,
                            "show-all": _vm.showAllOverallVolume,
                            "enable-navigation": _vm.enableNavigation,
                            "data-onboarding-step": "11",
                          },
                          on: {
                            "update:showAll": function ($event) {
                              _vm.showAllOverallVolume = $event
                            },
                            "update:show-all": function ($event) {
                              _vm.showAllOverallVolume = $event
                            },
                            "change-bar": _vm.setSelectedBar,
                            "change-enable-navigation": function ($event) {
                              _vm.enableNavigation = $event
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              !_vm.isMobile && !_vm.isDesktop
                                ? {
                                    key: "header",
                                    fn: function () {
                                      return [
                                        _c("select-date", {
                                          staticClass: "select-date-mobile",
                                          attrs: {
                                            "first-bar": _vm.barsFirstItem,
                                            "last-bar": _vm.barsLastItem,
                                            "is-mobile": "",
                                          },
                                          model: {
                                            value: _vm.filterDate,
                                            callback: function ($$v) {
                                              _vm.filterDate = $$v
                                            },
                                            expression: "filterDate",
                                          },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  }
                                : null,
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
          ]),
        ],
        1
      ),
      !_vm.isGuest
        ? _c("notification-modal", {
            key: _vm.showNotificationKey,
            attrs: {
              pairsData: _vm.pairsData,
              notification: _vm.notification,
              showModal: _vm.showNotificationModal,
              isFuturesForRerender: _vm.isFuturesForRerender,
              focusComment: true,
            },
            on: {
              "update:pairsData": function ($event) {
                _vm.pairsData = $event
              },
              "update:pairs-data": function ($event) {
                _vm.pairsData = $event
              },
              "update:isFuturesForRerender": function ($event) {
                _vm.isFuturesForRerender = $event
              },
              "update:is-futures-for-rerender": function ($event) {
                _vm.isFuturesForRerender = $event
              },
              close: _vm.closeEvent,
            },
          })
        : _vm._e(),
      !_vm.isGuest
        ? _c(
            "confirmModal",
            {
              attrs: {
                show: _vm.showConfirmModal,
                title: _vm.$t("lk.notifications.deleteNotification"),
              },
              on: {
                confirm: function ($event) {
                  return _vm.deleteDraw()
                },
                close: function ($event) {
                  _vm.showConfirmModal = false
                },
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("lk.notifications.deleteNotificationText")) +
                  " "
              ),
            ]
          )
        : _vm._e(),
      !_vm.isGuest
        ? _c(
            "confirmModal",
            {
              attrs: {
                show: _vm.propsDropdown?.showConfirmRemoveSignalsPlotModal,
                title: _vm.$t("tvPropsDropdown.confirmRemoveSignalsPlot") + "?",
              },
              on: {
                confirm: function ($event) {
                  return _vm.removeSignalsPlotCallback()
                },
                close: function ($event) {
                  _vm.propsDropdown.showConfirmRemoveSignalsPlotModal = false
                },
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("tvPropsDropdown.confirmRemoveSignalsPlotDesc")
                  ) +
                  " "
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }