<template>
  <CModal
    v-on="$listeners"
    :title="modalTitle"
    :show="show"
    centered
    closeOnBackdrop
    scrollable
    @update:show="closeModal"
  >
    <v-date-picker
      :value="value"
      :popover="{
        visibility: 'visible'
       }"
      v-bind="calendarProps"
      v-on="$listeners"
      :is-dark="$store.getters.darkMode"
      :locale="$root.$i18n.locale"
      is-expanded
      @input="inputValue = $event"
    />

    <template v-slot:footer>
      <div class="w-100 d-flex justify-content-end">
        <CLoadingButtonCustom
          color="primary"
          class="mr-2"
          :loading="loading"
          :disabled="disabled"
          @click.native="save"
        >
          {{ $t('general.save') }}
        </CLoadingButtonCustom>
        <CButton
          color="dark"
          @click="closeModal"
        >
          {{ $t('general.cancel') }}
        </CButton>
      </div>
    </template>
  </CModal>
</template>

<script>
import {DatePicker} from "v-calendar";

export default {
  name: "CalendarModal",
  components: {
    'v-date-picker': DatePicker
  },
  props: {
    calendarProps: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    show: {
      type: Boolean,
      default: false
    },
    modalTitle: {
      type: String,
      default: ''
    },
    value: {
      type: null,
      default: ''
    },
  },
  data() {
    return {
      inputValue: null
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
    },
    save() {
      this.$emit('save', this.inputValue)
      this.$emit('closeModal')
    }
  },
}
</script>

<style scoped lang="scss">

</style>
