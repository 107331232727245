<template>
  <div class="custom-scroll-wrap">
    <slot></slot>

    <div
      ref="scroll"
      class="scroll"
      @mousedown.stop.prevent="scroll"
      @touchstart.stop.prevent="scroll"
    >
      <CIcon name="cil-resize-height"/>
    </div>
  </div>
</template>

<script>
import throttle from "lodash/throttle";

const BTN_START_POS_PERCENTAGE = 30

export default {
  name: "CustomScroll",
  data() {
    return {
      showScrollBtn: false,
      moving: false,
      content: null,
    }
  },
  watch: {
    moving(val) {
      if (val) {
        document.documentElement.style.pointerEvents = 'none'
      } else {
        document.documentElement.style.pointerEvents = ''
      }
    }
  },
  mounted() {
    this.content = this.$slots.default?.[0]?.elm

    if (this.content) {
      this.setScrollBtnPos()

      let observer = new MutationObserver(this.setScrollBtnPos)

      observer.observe(this.content, {
        childList: true,
        subtree: true
      })

      this.content.addEventListener('scroll', this.setScrollBtnPos)
      document.addEventListener('resize', this.setScrollBtnPos)
    }
  },
  beforeDestroy() {
    document.documentElement.style.pointerEvents = ''
    document.removeEventListener('resize', this.setScrollBtnPos)

    if (this.content) {
      this.content.removeEventListener('resize', this.setScrollBtnPos)
    }
  },
  methods: {
    setScrollBtnPos() {
      if (this.content) {
        if (this.moving) return

        const scrollHeight = this.content?.scrollHeight || 0
        const height = this.content?.getBoundingClientRect()?.height || 0
        const scrollTopStart = this.content?.scrollTop
        const scrollHeightMax = scrollHeight - height
        const buttonTopPercentage = scrollTopStart * 100 / scrollHeightMax

        if (scrollHeight > height) {
          this.showScrollBtn = true

          const button = this.$refs['scroll']

          if (button) {
            button.style.top = `${BTN_START_POS_PERCENTAGE + buttonTopPercentage / 2}%`
          }
        } else {
          this.showScrollBtn = false
        }

      } else {
        this.showScrollBtn = false
      }
    },
    scroll(e) {
      e.preventDefault()

      const button = this.$refs['scroll']

      if (this.content && button) {
        const scrollHeight = this.content?.scrollHeight || 0
        const height = this.content?.getBoundingClientRect()?.height || 0
        const start = e.clientY || e.changedTouches?.[0]?.clientY || e.srcEvent || 0
        const scrollTopStart = this.content?.scrollTop
        const scrollHeightMax = scrollHeight - height
        const buttonTop = window.getComputedStyle(button)?.top?.split('px')?.[0] || 0
        const buttonTopPercentageStart = buttonTop * 100 / height

        if (scrollHeight > height) {
          const move = throttle((e) => {
            e.preventDefault()
            const scrollTop = (e.clientY || e.changedTouches?.[0]?.clientY || 0) - start
            const buttonTopPercentage = scrollTop / (height * .01)
            const buttonTopResult = buttonTopPercentageStart + buttonTopPercentage
            const buttonTopMax = 100 - (50 - BTN_START_POS_PERCENTAGE)

            if (buttonTopResult >= BTN_START_POS_PERCENTAGE && buttonTopResult <= buttonTopMax) {
              this.content.scrollTop = scrollTopStart + ((scrollHeightMax * (buttonTopPercentage * 2)) / 100)

              button.style.top = `${buttonTopResult}%`
            } else if (buttonTopResult < BTN_START_POS_PERCENTAGE) {
              this.content.scrollTop = 0

              button.style.top = `${BTN_START_POS_PERCENTAGE}%`
            } else if (buttonTopResult > buttonTopMax) {
              this.content.scrollTop = scrollHeightMax

              button.style.top = `${buttonTopMax}%`
            }
          }, 10)

          this.moving = true

          document.addEventListener('mousemove', move, {passive: false})

          document.addEventListener('touchmove', move, {passive: false})

          document.addEventListener('mouseup', (e) => {
            e.preventDefault()
            document.removeEventListener('mousemove', move)
            this.moving = false
          }, {once: true})

          button.addEventListener('mouseup', (e) => {
            e.preventDefault()
            document.removeEventListener('mousemove', move)
            this.moving = false
          }, {once: true})

          document.addEventListener('touchend', (e) => {
            e.preventDefault()
            document.removeEventListener('touchmove', move)
            this.moving = false
          }, {once: true, passive: false})

          button.addEventListener('touchend', (e) => {
            e.preventDefault()
            document.removeEventListener('touchmove', move)
            this.moving = false
          }, {once: true, passive: false})
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.scroll {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 100;
  pointer-events: all;
  cursor: move;
  background: var(--scroll-handler);
  border-left: 1px solid var(--input-contrast);
  border-right: none;
  border-top: none;
  border-bottom: none;
  width: 2rem;
  height: 2rem;
  border-radius: 50% 0 0 50%;
  -webkit-touch-callout: none;
  user-select: none;
  will-change: top;
  touch-action: none;
  display: flex;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-up(xl) {
    display: none;
  }
}

.custom-scroll-wrap {
  position: relative;
  height: 100%;
  overflow: hidden;
}
</style>
