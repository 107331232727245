var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "custom-scroll-wrap" },
    [
      _vm._t("default"),
      _c(
        "div",
        {
          ref: "scroll",
          staticClass: "scroll",
          on: {
            mousedown: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.scroll.apply(null, arguments)
            },
            touchstart: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.scroll.apply(null, arguments)
            },
          },
        },
        [_c("CIcon", { attrs: { name: "cil-resize-height" } })],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }