import { get } from "lodash";
import MarketVolumeItem from "@/assets/js/marketVolumeClasses/MarketVolumeItem.class";
import store from "@/store";

export default {
  _data: [],
  sortCol: null,
  sortType: null,
  lastSymbols: {},

  get data () {
    return this.sortedData
  },
  set data (val) {
    if (val && Array.isArray(val)) {
      this._data = val.map(i => new MarketVolumeItem(i))
    } else {
      this._data = []
    }
  },

  /**
   * return row by exchange
   * @param exchange string
   * @returns {null|MarketVolumeItem}
   */
  getExchangeRow (exchange) {
    if (!exchange || !this._data || this._data.length === 0) return null

    return this._data.find(v => v.exchange === exchange)
  },

  getSymbolsByExchange (exchange) {
    if (!exchange) return []
    if (!this._data || !this._data.length) return []

    const select = this._data.find(a => a.exchange_key.toLowerCase() === exchange.toLowerCase())
    if (!select) return []
    return select.symbols
  },

  get sortedData () {
    if (this.sortCol && this.sortType) {
      const arr = [].concat(this._data)

      let more = null
      let less = null
      switch (this.sortType) {
        case 'asd':
          more = 1
          less = -1
          break
        case 'desc':
        default:
          more = -1
          less = 1
          break
      }

      arr.sort((a, b) => {
        // не сортировать первую строку OVERALL
        if (a.exchange === 'OVERALL' || b.exchange === 'OVERALL') return 0

        const {valA, valB} = convertSort(a, b, this.sortCol)

        if (valA === valB) return 0

        return valA < valB ? less : more
      })

      return arr
    } else {
      return this._data
    }
  }
}

function convertSort(a, b, colName) {
  let valA = null
  let valB = null

  const value = get(a, `${colName}`, null)
  if (value || value === 0) {
    valA = value
    valB = get(b, `${colName}`, null)
  }

  return {
    valA,
    valB
  }
}
