<template>
  <div class="d-flex user-select-none">
    <div class="text-wrap">
      <span class="text">
        {{label}}
      </span>
    </div>
    <span
      class="rounded-circle bg-dark popover-wrap d-inline-flex justify-content-center ml-1"
      v-if="hintContent"
      v-c-tooltip="{
        header: label,
        content: hintContent,
        appendToBody: true,
        popperOptions: {
          strategy: 'fixed'
        }
      }"
    >
      ?
    </span>
    <CIcon
      v-if="keyCol && label"
      class="switcher ml-1"
      :class="{
        _active: sorting,
        _reverse: sorting === 'asd'
      }"
      name="arrow-strong"
      @click.native="changeSort"
    />
    <span class="symbol-temp">I</span> <!-- без текста ячейка схлопывается по высоте, добавлено как временное решение -->
  </div>
</template>

<script>
import marketVolumeAdapter from "@/assets/js/marketVolumeAdapter"
export default {
  name: "TableCellHeader",
  props: {
    keyCol: String,
    label: String,
    hintContent: String
  },
  data () {
    return {
      adapter: marketVolumeAdapter
    }
  },
  computed: {
    sorting () {
      if (
        this.adapter.sortCol === this.keyCol &&
        ['asd', 'desc'].indexOf(this.adapter.sortType) !== -1
      ) {
        return this.adapter.sortType
      } else {
        return null
      }
    }
  },
  methods: {
    changeSort () {
      if (this.adapter.sortCol === this.keyCol) {
        if (!this.sorting) {
          this.adapter.sortType = 'desc'
        } else if (this.sorting === 'desc') {
          this.adapter.sortType = 'asd'
        } else {
          this.adapter.sortType = null
        }
      } else {
        this.adapter.sortCol = this.keyCol
        this.adapter.sortType = 'desc'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
//@import "@/assets/scss/style";

.switcher {
  cursor: pointer;
  z-index: 1;
  opacity: 0.15;
  transition: all 250ms ease-out;
  &:hover {
    opacity: 0.3;
  }
  &._active {
    opacity: 1;
  }
  &._reverse {
    transform: rotate(180deg);
  }
}
.popover-wrap {
  cursor: pointer;
  display: inline-flex;
  width: 1rem;
  height: 1rem;
  font-size: 0.75rem;
}
.text-wrap {
  position: relative;
  flex-grow: 1;
  min-width: 1.2rem;
}
.text {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  min-width: 1rem;
  z-index: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.symbol-temp {
  color: transparent;
  width: 0;
  overflow: hidden;
}
</style>
