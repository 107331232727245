var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex user-select-none" },
    [
      _c("div", { staticClass: "text-wrap" }, [
        _c("span", { staticClass: "text" }, [
          _vm._v(" " + _vm._s(_vm.label) + " "),
        ]),
      ]),
      _vm.hintContent
        ? _c(
            "span",
            {
              directives: [
                {
                  name: "c-tooltip",
                  rawName: "v-c-tooltip",
                  value: {
                    header: _vm.label,
                    content: _vm.hintContent,
                    appendToBody: true,
                    popperOptions: {
                      strategy: "fixed",
                    },
                  },
                  expression:
                    "{\n      header: label,\n      content: hintContent,\n      appendToBody: true,\n      popperOptions: {\n        strategy: 'fixed'\n      }\n    }",
                },
              ],
              staticClass:
                "rounded-circle bg-dark popover-wrap d-inline-flex justify-content-center ml-1",
            },
            [_vm._v(" ? ")]
          )
        : _vm._e(),
      _vm.keyCol && _vm.label
        ? _c("CIcon", {
            staticClass: "switcher ml-1",
            class: {
              _active: _vm.sorting,
              _reverse: _vm.sorting === "asd",
            },
            attrs: { name: "arrow-strong" },
            nativeOn: {
              click: function ($event) {
                return _vm.changeSort.apply(null, arguments)
              },
            },
          })
        : _vm._e(),
      _c("span", { staticClass: "symbol-temp" }, [_vm._v("I")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }