import get from 'lodash/get'
export default class MarketVolumeItem {
  constructor (init = {}) {
    this.exchange = get(init, 'exchange', '—')
    this.exchange_key = get(init, 'exchange_key', '')
    this.quote_volume_delta = get(init, 'quote_volume_delta', '—')
    this.quote_volume_delta_str = get(init, 'quote_volume_delta_str', '—')
    this.quote_volume = get(init, 'quote_volume', '—')
    this.quote_volume_str = get(init, 'quote_volume_str', '—')
    this.open = get(init, 'open')
    this.openStr = get(init, 'open_str', '—')
    this.close = get(init, 'close')
    this.closeStr = get(init, 'close_str', '—')
    this.symbols = symbolsConstants[this.exchange_key || 'default']// get(init, 'symbols', [])
    this._cellClasses = {}
    this._classes = get(init, '_classes', '')

    if (this.exchange_key === 'OVERALL') {
      if(this.quote_volume_delta < 0){
        this._classes += ' border-overall bg-market-volume-danger'
      }
      if(this.quote_volume_delta > 0){
        this._classes += ' border-overall bg-market-volume-success'
      }
    } else {
      if (this.quote_volume_delta < 0) {
        this._cellClasses.quote_volume_delta_str += ' bg-market-volume-danger'
      }
      if (this.quote_volume_delta > 0) {
        this._cellClasses.quote_volume_delta_str += ' bg-market-volume-success'
      }
    }
  }

  removeFontWeight () {
    this._classes = this._classes.replace('font-weight-bold active-row', '')
    this._classes.trim()
  }
  addFontWeight () {
    this._classes += ' font-weight-bold active-row'
  }
}

export const symbolsConstants = {
  'default': [],
  'OVERALL': ['BTCUSD'],
  'BINANCE': ['BTCUSDT', 'BTCTUSD', 'BTCUSDC'],
  'BITFINEX': ['BTCUSD'],
  'BITSTAMP': ['BTCUSD', 'BTCUSDT'],
  'COINBASE': ['BTCUSD', 'BTCUSDT'],
  'FTX': ['BTCUSD', 'BTCUSDT'],
  'HUOBI': ['BTCUSDT'],
  'KRAKEN': ['BTCUSD', 'BTCUSDT'],
  'KUCOIN': ['BTCUSDT', 'BTCUSDC'],
  'HITBTC': ['BTCUSDT'],
}
